<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    persistent
    max-width="600px"
    @input="dialogChanged"
  >
    <v-card>
      <v-card-title v-if="value && value.uuid">
        {{ $t('editCustomer') }}
      </v-card-title>
      <v-card-title v-else>
        {{ $t('createCustomer') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('anyChangesYouMakeAreNotUpdatedUntilSaved') }}
      </v-card-subtitle>
      <v-card-text>
        <v-expansion-panels
          accordion
          mandatory
          tile
        >
          <v-expansion-panel
            v-for="(panel, i) in panels"
            :key="i"
          >
            <v-expansion-panel-header>{{ panel.title }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col
                  :cols="field.cols || '12'"
                  v-for="(field, index) in panel.fields"
                  :key="index"
                >
                  <v-text-field
                    v-if="field.type === 'text' && field.key !== 'ssn'"
                    dense
                    :label="field.required ? field.label + ' *' : field.label"
                    :type="field.type"
                    filled
                    :required="field.required"
                    v-model="value[field.key]"
                    :prepend-icon="field.icon"
                  />
                  <v-text-field
                    v-if="field.type === 'text' && field.key === 'ssn'"
                    dense
                    :label="field.required ? field.label + ' *' : field.label"
                    :type="field.type"
                    filled
                    :required="field.required"
                    v-model="value[field.key]"
                    :prepend-icon="field.icon"
                    :error-messages="ssnExists ? $t('customerWithThatIdSSNExists') : ''"
                    :loading="field.loading"
                  />
                  <v-text-field
                    v-if="field.type === 'email'"
                    dense
                    :label="field.required ? field.label + ' *' : field.label"
                    :type="field.type"
                    filled
                    :required="field.required"
                    v-model="value[field.key]"
                    :prepend-icon="field.icon"
                  />
                  <v-text-field
                    v-if="field.type === 'phone'"
                    dense
                    :label="field.required ? field.label + ' *' : field.label"
                    :type="field.type"
                    filled
                    :required="field.required"
                    v-model="value[field.key]"
                    :prepend-icon="field.icon"
                  />
                  <v-text-field
                    v-if="field.type === 'number'"
                    dense
                    :label="field.required ? field.label + ' *' : field.label"
                    :type="field.type"
                    filled
                    :required="field.required"
                    v-model="value[field.key]"
                    :prepend-icon="field.icon"
                  />
                  <v-checkbox
                    v-if="field.type === 'checkbox'"
                    dense
                    :label="field.required ? field.label + ' *' : field.label"
                    filled
                    :required="field.required"
                    v-model="value[field.key]"
                    :prepend-icon="field.icon"
                    :key="forceRenderKey"
                    @change="checkboxChanged(field, value[field.key])"
                  />
                  <v-select
                    v-if="field.type === 'select'"
                    dense
                    :label="field.required ? field.label + ' *' : field.label"
                    filled
                    :required="field.required"
                    v-model="value[field.key]"
                    :items="field.options"
                    item-value="key"
                    item-text="label"
                    :prepend-icon="field.icon"
                  />
                  <v-autocomplete
                    v-if="field.type === 'currency'"
                    dense
                    :label="field.required ? field.label + ' *' : field.label"
                    filled
                    :items="currencies"
                    item-text="name"
                    item-value="code"
                    :required="field.required"
                    v-model="value[field.key]"
                    :prepend-icon="field.icon"
                    no-data-text="Could not find currency"
                  >
                    <template v-slot:item="{ item }">
                      <span>{{ item.name }} <span class="caption">({{ item.code }})</span></span>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span>{{ item.name }} <span class="caption">({{ item.code }})</span></span>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    v-if="field.type === 'autocomplete'"
                    dense
                    :label="field.required ? field.label + ' *' : field.label"
                    filled
                    :items="field.options"
                    item-text="label"
                    item-value="key"
                    :required="field.required"
                    v-model="value[field.key]"
                    :prepend-icon="field.icon"
                    :no-data-text="$t('couldNotFindValue')"
                  />
                  <v-autocomplete
                    v-if="field.type === 'country'"
                    dense
                    :label="field.required ? field.label + ' *' : field.label"
                    filled
                    :items="countries"
                    item-text="name"
                    item-value="code"
                    :required="field.required"
                    v-model="value[field.key]"
                    :prepend-icon="field.icon"
                    :no-data-text="$t('couldNotFindCountry')"
                  >
                    <template v-slot:item="{ item }">
                      <span>{{ item.name }} <span class="caption">({{ item.code }})</span></span>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span>{{ item.name }} <span class="caption">({{ item.code }})</span></span>
                    </template>
                  </v-autocomplete>
                  <DateSelection
                    v-if="field.type === 'date'"
                    :label="field.required ? field.label + ' *' : field.label"
                    v-model="value[field.key]"
                    dense
                    filled
                    :required="field.required"
                    :icon="field.icon"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          :disabled="saving"
          color="red darken-1"
          text
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="value && value.uuid"
          :disabled="saving || !checkIfRequiredValuesHaveBeenFilled"
          :loading="saving"
          color="primary"
          @click="save"
        >
          {{ $t('save') }}
        </v-btn>
        <v-btn
          v-else
          :disabled="saving || ssnExists || !checkIfRequiredValuesHaveBeenFilled"
          :loading="saving"
          color="primary"
          @click="create"
        >
          {{ $t('create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateSelection from "@/components/common/DateSelection"

export default {
  name: 'CustomerDialog',
  components: {
    DateSelection
  },
  props: {
    dialog: {
      type: Boolean
    },
    value: {
      type: Object,
      default: () => {}
    },
    minimized: {
      type: Boolean
    }
  },
  data() { 
    return {
      processingIDLookup: false,
      panels: [
        {
          title: this.$t('contact'),
          description: this.$t('theContactInformation'),
          icon: 'mdi-domain',
          fields: [
            {
              key: 'ssn',
              label: this.$t('id'),
              type: 'text',
              required: true,
              loading: false,
            },
            {
              key: 'label',
              label: this.$t('label'),
              type: 'text',
              required: false,
              loading: false,
            },
            {
              key: 'name_line',
              label: this.$t('name'),
              type: 'text',
              required: true
            },
            {
              key: 'email',
              label: this.$t('email'),
              type: 'email',
              required: true
            },
            {
              key: 'phone',
              label: this.$t('phone'),
              type: 'phone',
              required: true
            },
            {
              key: 'country',
              label: this.$t('country'),
              type: 'country',
              required: true
            },
            {
              key: 'invoiceable',
              label: this.$t('invoicable'),
              type: 'checkbox',
              required: false
            },
            {
              key: 'deliverInvoice',
              label: this.$t('deliverInvoice'),
              type: 'checkbox',
              required: false
            },
          ]
        },
        {
          title: this.$t('pricing'),
          description: this.$t('thePricingInformation'),
          icon: 'mdi-domain',
          fields: [
            {
              key: 'discount',
              label: this.$t('discount%'),
              type: 'autocomplete',
              options: [...Array(21).keys()].map(x => {
                if(x === 0) {
                  return {
                    label: this.$t('none'),
                    key: x*5
                  }
                } else {
                  return x*5
                }
              })
            },
            {
              key: 'currency',
              label: this.$t('currencyCode'),
              type: 'currency'
            },
            {
              key: 'exclusive',
              label: this.$t('exclusive'),
              type: 'checkbox'
            }
          ]
        },
        {
          title: this.$t('personal'),
          description: this.$t('thePersonalInformation'),
          icon: 'mdi-domain',
          fields: [
            {
              label: this.$t('gender'),
              type: 'select',
              key: 'gender',
              icon: 'mdi-gender-male-female',
              options: [
                {
                  key: 'male',
                  label: this.$t('male')
                },
                {
                  key: 'female',
                  label: this.$t('female')
                },
                {
                  key: 'other',
                  label: this.$t('other')
                }
              ]
            },
            {
              label: 'Passport',
              type: 'text',
              required: false,
              key: 'passport',
              icon: 'mdi-passport'
            },
            {
              label: this.$t('passportExpiration'),
              type: 'date',
              required: false,
              key: 'passport_expiration_date',
            }
          ]
        }
      ],
      startIndex: 0,
      isLoadingMore: false,
      saving: false,
      forceRenderKey: 0
    }
  },
  methods: {
    checkboxChanged(field, value) {
      console.log(value)
      if(field.key === 'invoiceable' && value === true) {
        this.value['deliverInvoice'] = true
        this.forceRenderKey += 1
      }
    },
    close() {
      this.$emit('closed')
    },
    create() {
      this.saving = true
      if(this.value['currency']) {
        this.value['currency'] = { code: this.value['currency']	}
      }
      if(this.value['passport_expiration_date']) {
        this.value['passport_expiration_date'] = this.value['passport_expiration_date'] + ""
      }
      this.$store.dispatch('createCustomer', this.value)
        .then(response => {
          if(response !== null && response !== undefined) {
            let message = this.$t('customer') + ' ' +this.value.address.name_line + ' ' + this.$t('successfullyAdded')
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: message
            })
            this.$emit('created', response)
          }
          else {
            this.$store.commit('updateActionError', {
              message: this.$t('error'),
              subMessage: this.$t('couldNotCreateCustomer')
            })
            this.$emit('closed')
          }
        })
        .catch(error => {
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: this.$t('couldNotCreateCustomer')
          })
          this.$emit('closed')
        })
        .finally(() => {
          this.saving = false
        })
    },
    save() {
      this.saving = true
      if(this.value['currency']) {
        this.value['currency'] = { code: this.value['currency']	}
      }
      if(this.value['passport_expiration_date']) {
        this.value['passport_expiration_date'] = this.value['passport_expiration_date'] + ""
      }
      this.$store.dispatch('updateCustomer', this.value)
        .then(response => {
          this.$emit('updated', response)
        }).finally(() => {
        this.saving = false
      })
    },
    dialogChanged(dialog) {
      this.$emit('dialog', dialog)
    },
    fetchNameById(id) {
      return this.$store.dispatch('getNameBySSNFromNationalRegister', id)
    }
  },
  computed: {
    checkIfRequiredValuesHaveBeenFilled() {
      const requiredFields = this.panels.map(panel => panel.fields).flat().filter(field => field.required)
      return requiredFields.every(field => this.value[field.key])
    },
    ssnExists() {
      if(this.value && this.value.ssn) {
        return this.customers.some(customer => customer.ssn === this.value.ssn)
      } else {
        return false
      }
    },
    customers() {
      return this.$store.state.customers
    },
    countries() {
      let countries = this.$store.state.countries.slice()
      return countries.sort((a,b) => a.name.localeCompare(b.name))
      //return this.$store.state.countries
    },
    currencies() {
      let currencies = this.$store.state.currencies.slice()
      return currencies.sort((a,b) => a.name.localeCompare(b.name))
    },
    defaultCurrency() {
      return this.$store.state.defaultCurrency.code
    }
  },
  created() {
    if(this.minimized) {
      this.panels = this.panels.filter(panel => panel.title === 'Contact')
    }
  },
  watch: {
    'value.ssn' : function (newValue) {
      this.value.ssn = newValue.replace(/\D/g,'')
        if(this.value.ssn.length === 10) {
          this.panels[0].fields[0].loading = true
          this.fetchNameById(this.value.ssn).then(lookup => {
          if(lookup && lookup.success && lookup.data && lookup.data.name && lookup.data.name.length > 0) {
            this.value.name_line = lookup.data.name[0]
          }
        })
        .finally(() => {
          this.panels[0].fields[0].loading = false
        })
      }
    },
    value: {
      deep: true,
      handler() {
        return
        
        if (this.value['invoiceable'] === true) {
          console.log('change deliver invoice')
          this.value['deliverInvoice'] = true
        }
      }
    }
  }
}
</script>
